import {
  GET_PIZZA,
  FAILURE_UPDATE_PIZZA,
  FAILURE_GET_PIZZA,
  UPDATE_PIZZA_SUCCESS,
  IS_UPDATE_PIZZA,
  SUCCESS_GET_PIZZA,
  ADD_PIZZA_SUCCESS,
  FAILURE_ADD_PIZZA,
  IS_ADD_PIZZA,
  DELETE_PIZZA_SUCCESS,
  FAILURE_DELETE_PIZZA,
  IS_DELETE_PIZZA,
  SUCCESS_GET_DETAILS_PIZZA,
  FAILURE_GET_DETAILS_PIZZA,
  GET_DETAILS_PIZZA,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
const axios = require("axios");

function get_pizza_api(params) {
  const { page, per_page, search, token } = params.params;
  let url = "/api/cms/pizza/list?page=" + page + "&per_page=" + per_page;
  if (search) {
    url =
      "/api/cms/pizza/list?page=" +
      page +
      "&per_page=" +
      per_page +
      "&search=" +
      search;
  }
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function get_detail_pizza_api(params) {
  const { id, token } = params.params;
  let url = `api/cms/pizza/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function update_pizza_api(params) {
  const {
    name,
    price,
    image,
    tags,
    description,
    token,
    id,
    type,
    status,
    index,
    toppings,
  } = params.params;

  const data = new FormData();

  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("description", description);
  data.append("price", price);
  data.append("tags", JSON.stringify(tags));
  data.append("type", type);
  data.append("name", name);
  data.append("id", id);
  data.append("status", status);
  data.append("index", index);
  data.append("toppings", toppings);

  return axios.request({
    method: "put",
    url: "/api/cms/pizza/update",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function add_pizza_api(params) {
  const {
    token,
    image,
    name,
    description,
    tags,
    type,
    price,
    toppings,
  } = params.params;

  const data = new FormData();

  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("description", description);
  data.append("price", price);
  data.append("tags", JSON.stringify(tags));
  data.append("type", type);
  data.append("name", name);
  data.append("toppings", JSON.stringify(toppings));

  return axios.request({
    method: "post",
    url: "/api/cms/pizza/add",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function delete_pizza_api(params) {
  const { token, id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/pizza/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": token },
  });
}

function* get_pizza(params) {
  try {
    const res = yield call(get_pizza_api, params);
    yield put({
      type: SUCCESS_GET_PIZZA,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_PIZZA,
      error: err,
    });
  }
}

function* get_detail_pizza(params) {
  try {
    const res = yield call(get_detail_pizza_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_PIZZA,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_PIZZA,
      error: err,
    });
  }
}

function* update_pizza(params) {
  try {
    const res = yield call(update_pizza_api, params);
    yield put({
      type: UPDATE_PIZZA_SUCCESS,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_UPDATE_PIZZA,
      error: err,
    });
  }
}

function* add_pizza(params) {
  try {
    const res = yield call(add_pizza_api, params);
    yield put({
      type: ADD_PIZZA_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_PIZZA,
      payload: error,
    });
  }
}

function* delete_pizza(params) {
  try {
    const res = yield call(delete_pizza_api, params);
    yield put({
      type: DELETE_PIZZA_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_PIZZA,
      payload: error,
    });
  }
}

export function* watchPizza() {
  yield takeEvery(GET_PIZZA, get_pizza);
  yield takeEvery(IS_UPDATE_PIZZA, update_pizza);
  yield takeEvery(IS_ADD_PIZZA, add_pizza);
  yield takeEvery(IS_DELETE_PIZZA, delete_pizza);
  yield takeEvery(GET_DETAILS_PIZZA, get_detail_pizza);
}
