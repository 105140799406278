import {
  FAILURE_GET_DETAIL_ORDER,
  FAILURE_GET_ORDER,
  FAILURE_UPDATE_ORDER,
  GET_DETAIL_ORDER,
  GET_ORDER,
  SUCCESS_GET_DETAIL_ORDER,
  SUCCESS_GET_ORDER,
  SUCCESS_UPDATE_ORDER,
  UPDATE_ORDER,
} from "constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";

const axios = require("axios");

function get_order_api(params) {
  const { token, page, per_page, search, sortByDate } = params.params;
  console.log({ xxx: params.params });
  let url =
    "api/cms/order/list?page=" +
    page +
    "&per_page=" +
    per_page +
    "&sortByDate=" +
    sortByDate;
  if (search) {
    url =
      "api/cms/order/list?page=" +
      page +
      "&per_page=" +
      per_page +
      "&search=" +
      search;
  }
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function get_detail_order_api(params) {
  const { id } = params.params;
  const token = localStorage.getItem("x-access-token");
  let url = `api/cms/order/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function update_order_api(params) {
  const { deliver_status, id } = params.params;
  const token = localStorage.getItem("x-access-token");

  return axios.request({
    method: "put",
    url: `/api/cms/order/update/${id}`,
    data: { deliver_status },
    headers: {
      "x-access-token": token,
    },
  });
}

// function* get_order_print(params) {
//   try {
//     const res = yield call(get_order_api, params);
//     console.log("REE", res);
//     yield put({
//       type: SUCCESS_GET_ORDER_PRINT,
//       payload: res
//     });
//   } catch (error) {
//     yield put({
//       type: FAILURE_GET_ORDER_PRINT,
//       payload: error
//     });
//   }
// }

function* get_order(params) {
  try {
    const res = yield call(get_order_api, params);
    yield put({
      type: SUCCESS_GET_ORDER,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_GET_ORDER,
      payload: error,
    });
  }
}

function* get_detail_order(params) {
  try {
    const res = yield call(get_detail_order_api, params);
    yield put({
      type: SUCCESS_GET_DETAIL_ORDER,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAIL_ORDER,
      error: err,
    });
  }
}

function* update_order(params) {
  try {
    const res = yield call(update_order_api, params);
    yield put({
      type: SUCCESS_UPDATE_ORDER,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_UPDATE_ORDER,
      error: err,
    });
  }
}

export function* watchOrder() {
  yield takeEvery(GET_ORDER, get_order);
  yield takeEvery(UPDATE_ORDER, update_order);
  yield takeEvery(GET_DETAIL_ORDER, get_detail_order);
}
