import {
  GET_REPORTS,
  SUCCESS_GET_REPORTS,
  FAILURE_GET_REPORTS,
  GET_REPORTS_BY_AMOUNT_PAID,
  SUCCESS_GET_REPORTS_BY_AMOUNT_PAID,
  FAILURE_GET_REPORTS_BY_AMOUNT_PAID,
  GET_REPORTS_USER,
  SUCCESS_GET_REPORTS_USER,
  FAILURE_GET_REPORTS_USER,
  GET_REPORTS_USER_REGISTER,
  SUCCESS_GET_REPORTS_USER_REGISTER,
  FAILURE_GET_REPORTS_USER_REGISTER,
} from "constants/ActionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
const axios = require("axios");

function get_reports_api(params) {
  const { date, token, startDate, endDate, tags } = params.params;
  let data = {
    date,
    start_date: startDate,
    end_date: endDate,
    tags
  };
  if (!tags || tags.length == 0) {
    data.tags = ["all"];
  }

  let url = `api/cms/report/reports-by-time`;
  return axios.request({
    method: "post",
    url: url,
    headers: { "x-access-token": token },
    data
  });
}
function get_reports_user_api(params) {
  const { date, token, startDate, endDate } = params.params;
  let data = {
    date,
    start_date: startDate,
    end_date: endDate,
  };

  let url = `api/cms/report/reports-user-by-time`;
  return axios.request({
    method: "post",
    url: url,
    headers: { "x-access-token": token },
    data
  });
}

function get_reports_user_register_api(params) {
  const {  token } = params.params;
  let data = {
  
  };

  let url = `api/cms/report/reports-user`;
  return axios.request({
    method: "post",
    url: url,
    headers: { "x-access-token": token },
    data
  });
}

function get_reports_by_amount_paid_api(params) {
  const { date, token, startDate, endDate, tags } = params.params;
  let data = {
    date,
    start_date: startDate,
    end_date: endDate,
    tags
  };
  if (!tags || tags.length == 0) {
    data.tags = ["all"];
  }

  let url = `api/cms/report/reports-by-amount`;
  return axios.request({
    method: "post",
    url: url,
    headers: { "x-access-token": token },
    data
  });
}

function* get_reports(params) {
  try {
    const res = yield call(get_reports_api, params);
    yield put({
      type: SUCCESS_GET_REPORTS,
      payload: res
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORTS,
      error: err
    });
  }
}

function* get_reports_users(params) {
  try {
    const res = yield call(get_reports_user_api, params);
    yield put({
      type: SUCCESS_GET_REPORTS_USER,
      payload: res
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORTS_USER,
      error: err
    });
  }
}
function* get_reports_users_register(params) {
  try {
    const res = yield call(get_reports_user_register_api, params);
    yield put({
      type: SUCCESS_GET_REPORTS_USER_REGISTER,
      payload: res
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORTS_USER_REGISTER,
      error: err
    });
  }
}

function* get_reports_by_amount_paid(params) {
  try {
    const res = yield call(get_reports_by_amount_paid_api, params);
    console.log("Done calling api", res);
    yield put({
      type: SUCCESS_GET_REPORTS_BY_AMOUNT_PAID,
      payload: res
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORTS_BY_AMOUNT_PAID,
      error: err
    });
  }
}

export function* watchReports() {
  yield takeEvery(GET_REPORTS, get_reports);
}
export function* watchReportsUser() {
  yield takeEvery(GET_REPORTS_USER, get_reports_users);
}
export function* watchReportsUserRegister() {
  yield takeEvery(GET_REPORTS_USER_REGISTER, get_reports_users_register);
}
export function* watchReportsByAmountPaid() {
  yield takeEvery(GET_REPORTS_BY_AMOUNT_PAID, get_reports_by_amount_paid);
}
