import { GET_PROMO_CODE, SUCCESS_GET_PROMO_CODE, FAILURE_GET_PROMO_CODE, IS_ADD_NEW_PROMO_CODE, ADD_NEW_PROMO_CODE_SUCCESS, IS_UPDATE_PROMO_CODE, UPDATE_PROMO_CODE_SUCCESS } from 'constants/ActionTypes';

const initState = {
    isGetPromoCode: false,
    list : [],
    data : [],
    total: 0,
    page: 1,
    per_page: 50,
    isAddNewPromoCode: false,
    error: '',
    message: '',
};

const promoCodeReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_PROMO_CODE:
            return Object.assign({}, state, {
                isGetPromoCode: true,
            });
        case SUCCESS_GET_PROMO_CODE:
            return Object.assign({}, state, {
                isGetPromoCode: false,
                list: action.payload.data.data,
                total: action.payload.data.total,
                page: action.payload.data.page,
                per_page: action.payload.data.per_page,
                error: '',
                message: '',
            });
        case IS_ADD_NEW_PROMO_CODE:
            return Object.assign({}, state, {
                isAddNewPromoCode: true,
                error: '',
                message: '',
            });
        case ADD_NEW_PROMO_CODE_SUCCESS:
            return Object.assign({}, state, {
                isAddNewPromoCode: false,
                data: action.payload.data.data,
                message: 'Add promo code success',
                error: '',
            });
        case IS_UPDATE_PROMO_CODE:
            return Object.assign({}, state, {
                isUpdatePromoCode: true,
                error: '',
                message: '',
            });
        case UPDATE_PROMO_CODE_SUCCESS:
            return Object.assign({}, state, {
                message: 'Update promo code success',
                error: '',
            });
        case FAILURE_GET_PROMO_CODE:
            return Object.assign({}, state, {
                isGetPromoCode: false,
                isAddNewPromoCode: false,
                list: [],
                data: [],
                error: action.payload.response.data.message || action.payload.message,
                message: '',
            });
        default:
            return state;
    }
};

export default promoCodeReducer;
