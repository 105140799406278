/** @flow */
import { GET_LOGIN, LOGIN_SUCCESS_USER, LOGIN_ERROR_USER } from '../../constants/ActionTypes';
import { takeEvery, call, put } from 'redux-saga/effects';
const axios = require('axios');

function loginApi(authParams) {
  return axios.request({
    method: 'post',
    url: 'api/cms/auth/login',
    data: authParams,
  });
}

function* get_login(authParams){
    try {
        const response = yield call(loginApi, authParams);
        const { token, user } = response.data;
        localStorage.setItem('x-access-token', token);
        yield put({
            type: LOGIN_SUCCESS_USER,
            payload: {
                'data' : user,
            },
        });
    } catch (error) {
        yield put({
            type: LOGIN_ERROR_USER,
            payload: error,
        });
    }
}

export function* watchAuth(){
    yield takeEvery(GET_LOGIN, get_login);
}
