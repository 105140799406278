/** @flow */
import {
  GET_REPORTS,
  SUCCESS_GET_REPORTS,
  FAILURE_GET_REPORTS,
  GET_REPORTS_BY_AMOUNT_PAID,
  SUCCESS_GET_REPORTS_BY_AMOUNT_PAID,
  FAILURE_GET_REPORTS_BY_AMOUNT_PAID,
  GET_REPORTS_USER,
  SUCCESS_GET_REPORTS_USER,
  FAILURE_GET_REPORTS_USER,
  GET_REPORTS_USER_REGISTER,
  SUCCESS_GET_REPORTS_USER_REGISTER,
  FAILURE_GET_REPORTS_USER_REGISTER,
} from "constants/ActionTypes";
import _ from "lodash";
type State = {
  isLoadingReport: boolean,
  report: any,
  foods: array
};

const initState = {
  isLoadingReports: false,
  isLoadingReportUser: false,
  isLoadingReportUserRegister: false,
  isLoadingReportsOrder: false,
  reportFoods: [],
  reportUsers: [],
  reportTotalMoney: 0,
  reportTotalMoneyAfterDiscount: 0,
  reportPromoCodes: [],
  reportListItems: [],
  reportOrders: [],
  linkPDF: "",
  linkCSV: "",
  linkCSVProduct: "",
  linkPDFProduct: "",
  linkCSVUser: "",
  linkCSVUserRegister: "",
};

const reportReducer = (state: State = initState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return Object.assign({}, state, {
        isLoadingReports: true,
        linkCSVProduct: "",
        linkPDFProduct: ""
      });
    case GET_REPORTS_USER:
      return Object.assign({}, state, {
        isLoadingReportUser: true,
        linkCSVUser: "",
      });
    case GET_REPORTS_USER_REGISTER:
      return Object.assign({}, state, {
        isLoadingReportUserRegister: true,
        linkCSVUserRegister: "",
      });
    case GET_REPORTS_BY_AMOUNT_PAID:
      return Object.assign({}, state, {
        isLoadingReportsOrder: true,
        linkPDF: "",
        linkCSV: ""
      });
    case SUCCESS_GET_REPORTS:
      let all_today_items = [];
      let allFoods = [];
      let allCodes = [];
      let allMoney = 0;
      let allMoneyAfterDiscount = 0;
      let data = _.get(action, "payload.data.data");
      if (data) {
        let allItem = data;
        allFoods = allItem.foods;
        allCodes = allItem.discountCodeUse;
        allMoney = allItem.totalMoney;
        allMoneyAfterDiscount = allItem.totalMoneyAfterDiscount;
      }
      let pdfProduct = data.pdf;
      let csvProduct = data.csv;

      return Object.assign({}, state, {
        isLoadingReports: false,
        reportListItems: all_today_items,
        reportTotalMoney: allMoney,
        reportTotalMoneyAfterDiscount: allMoneyAfterDiscount,
        reportFoods: allFoods,
        reportPromoCodes: allCodes,
        linkCSVProduct: csvProduct,
        linkPDFProduct: pdfProduct
      });
    case SUCCESS_GET_REPORTS_USER:
      let data3 = _.get(action, "payload.data.data");
      let csvProduct3 = data3.csv;
      return Object.assign({}, state, {
        isLoadingReportUser: false,
        linkCSVUser: csvProduct3,
      });
    case SUCCESS_GET_REPORTS_USER_REGISTER:
      let data4 = _.get(action, "payload.data.data");
      let csvProduct4 = data4.csv;
      return Object.assign({}, state, {
        isLoadingReportUserRegister: false,
        linkCSVUserRegister: csvProduct4,
      });
    case FAILURE_GET_REPORTS:
      return Object.assign({}, state, {
        isLoadingReports: false
      });
    case FAILURE_GET_REPORTS_USER:
      return Object.assign({}, state, {
        isLoadingReportUser: false
      });
    case FAILURE_GET_REPORTS_USER_REGISTER:
      return Object.assign({}, state, {
        isLoadingReportUserRegister: false
      });
    case SUCCESS_GET_REPORTS_BY_AMOUNT_PAID:
      let allOrders = [];
      let all_today_items2 = [];
      let allCodes2 = [];
      let allMoney2 = 0;
      let allMoneyAfterDiscount2 = 0;
      let data2 = _.get(action, "payload.data.data");
      // console.log("data2data2", data2);
      if (data2) {
        allOrders = data2.orders;
        allCodes2 = data2.discountCodeUse;
        allMoney2 = data2.totalMoney;
        allMoneyAfterDiscount2 = data2.totalMoneyAfterDiscount;
      }
      let linkPDF = data2.pdf;
      let linkCSV = data2.csv;
      let newObjAmountPaid = Object.assign({}, state, {
        isLoadingReportsOrder: false,
        reportOrders: allOrders,
        reportListItems: all_today_items2,
        reportTotalMoney: allMoney2,
        reportFoods: allOrders,
        reportTotalMoneyAfterDiscount: allMoneyAfterDiscount2,
        reportPromoCodes: allCodes2,
        linkPDF,
        linkCSV
      });
      return newObjAmountPaid;
    case FAILURE_GET_REPORTS_BY_AMOUNT_PAID:
      return Object.assign({}, state, {
        isLoadingReportsOrder: false
      });
    default:
      return state;
  }
};

export default reportReducer;
