import {
    IS_UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    FAILURE_UPDATE_NOTIFICATION,
    SEND_NOTIFICATION_SUCCESS,
    IS_SEND_NOTIFICATION,
    SUCCESS_GET_NOTIFICATION,
    FAILURE_GET_NOTIFICATION,
    GET_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    FAILURE_DELETE_NOTIFICATION,
    IS_DELETE_NOTIFICATION,
} from 'constants/ActionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
const axios = require('axios');

function update_notification_api(params){
    const { token, type, message, sender } = params.params;
    return axios.request({
        method: 'put',
        url: '/api/cms/notification/update-welcome',
        data: {
            'type': type,
            'message': message,
            'sender': sender,
        },
        headers: {'x-access-token': token},
    });
}

function send_notification_api(params){
    let url = '/api/cms/notification/send_all';
    const { token, title, message, key } = params.params;
    let data = {
        'title': title,
        'message': message,
    };

    if (key) {
        data = {
            ...data,
            'key': key,
        }
    }

    return axios.request({
        method: 'post',
        url: url,
        data: data,
        headers: {'x-access-token': token},
    });
}

function* update_notification(params){
    try {
        const res = yield call(update_notification_api, params);
        yield put({
            type: UPDATE_NOTIFICATION_SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: FAILURE_UPDATE_NOTIFICATION,
            payload: error,
        });
    }
}

function* send_notification(params){
    try {
        const res = yield call(send_notification_api, params);
        yield put({
            type: SEND_NOTIFICATION_SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: FAILURE_UPDATE_NOTIFICATION,
            payload: error,
        });
    }
}

function get_notification_api(params) {
    const { token, page, per_page, search } = params.params;
    let url = "api/cms/notification/list?page=" + page + "&per_page=" + per_page;
    if (search) {
        url =
            "api/cms/notification/list?page=" +
            page +
            "&per_page=" +
            per_page +
            "&search=" +
            search;
    }
    return axios.request({
        method: "get",
        url: url,
        headers: { "x-access-token": token }
    });
}

function* get_notification(params) {
    try {
        const res = yield call(get_notification_api, params);
        yield put({
            type: SUCCESS_GET_NOTIFICATION,
            payload: res
        });
    } catch (error) {
        yield put({
            type: FAILURE_GET_NOTIFICATION,
            payload: error
        });
    }
}

function delete_notification_api(params) {
    const { token, id } = params.params;
    return axios.request({
        method: 'delete',
        url: '/api/cms/notification/delete',
        data: {
            'id' : id,
        },
        headers: {'x-access-token': token},
    });
}

function* delete_notification(params){
    try {
        const res = yield call(delete_notification_api, params);
        yield put({
            type: DELETE_NOTIFICATION_SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: FAILURE_DELETE_NOTIFICATION,
            payload: error,
        });
    }
}

export function* watchNotification(){
    yield takeEvery(IS_UPDATE_NOTIFICATION, update_notification);
    yield takeEvery(IS_SEND_NOTIFICATION, send_notification);
    yield takeEvery(GET_NOTIFICATION, get_notification);
    yield takeEvery(IS_DELETE_NOTIFICATION, delete_notification);
}
