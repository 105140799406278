import {
  GET_PIZZA,
  IS_UPDATE_PIZZA,
  FAILURE_GET_PIZZA,
  FAILURE_UPDATE_PIZZA,
  SUCCESS_GET_PIZZA,
  UPDATE_PIZZA_SUCCESS,
  IS_ADD_PIZZA,
  ADD_PIZZA_SUCCESS,
  FAILURE_ADD_PIZZA,
  IS_DELETE_PIZZA,
  DELETE_PIZZA_SUCCESS,
  FAILURE_DELETE_PIZZA,
  SUCCESS_GET_DETAILS_PIZZA,
  FAILURE_GET_DETAILS_PIZZA,
  GET_DETAILS_PIZZA,
} from "../../constants/ActionTypes";

const initState = {
  isGetPizza: false,
  isGetDetailPizza: false,
  isUpdatePizza: false,
  isAddPizza: false,
  isDeletePizza: false,
  list: [],
  page: 1,
  per_page: 50,
  total: 0,
  error: "",
  pizzaSelected: null,
};

const pizzaReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PIZZA:
      return Object.assign({}, state, {
        isGetPizza: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_PIZZA:
      return Object.assign({}, state, {
        isGetPizza: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        error: "",
        message: "",
      });
    case FAILURE_GET_PIZZA:
      return Object.assign({}, state, {
        isGetPizza: false,
        isUpdatePizza: false,
        isAddPizza: false,
        isDeletePizza: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case GET_DETAILS_PIZZA:
      return Object.assign({}, state, {
        isGetDetailPizza: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_PIZZA:
      return Object.assign({}, state, {
        isGetDetailPizza: false,
        pizzaSelected: action.payload.data.data,
      });
    case FAILURE_GET_DETAILS_PIZZA:
      return Object.assign({}, state, {
        isGetDrink: false,
        isUpdateDrink: false,
        isDeleteDrink: false,
        isAddDrink: false,
        isGetDetailPizza: false,
        pizzaSelected: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_UPDATE_PIZZA:
      return Object.assign({}, state, {
        isUpdatePizza: true,
        error: "",
        message: "",
      });
    case UPDATE_PIZZA_SUCCESS:
      return Object.assign({}, state, {
        isUpdatePizza: false,
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_PIZZA:
      return Object.assign({}, state, {
        isUpdatePizza: false,
        isGetPizza: false,
        isAddPizza: false,
        isDeletePizza: false,
        isGetDetailPizza: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_ADD_PIZZA:
      return Object.assign({}, state, {
        isAddPizza: true,
        error: "",
        message: "",
      });
    case ADD_PIZZA_SUCCESS:
      return Object.assign({}, state, {
        isAddPizza: false,
        message: true,
        error: "",
      });
    case FAILURE_ADD_PIZZA:
      return Object.assign({}, state, {
        isUpdatePizza: false,
        isGetPizza: false,
        isAddPizza: false,
        isDeletePizza: false,
        isGetDetailPizza: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_PIZZA:
      return Object.assign({}, state, {
        isDeletePizza: true,
        error: "",
        message: "",
      });
    case DELETE_PIZZA_SUCCESS:
      return Object.assign({}, state, {
        isDeletePizza: false,
        message: true,
        error: "",
      });
    case FAILURE_DELETE_PIZZA:
      return Object.assign({}, state, {
        isUpdatePizza: false,
        isGetPizza: false,
        isAddPizza: false,
        isDeletePizza: false,
        isGetDetailPizza: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default pizzaReducer;
