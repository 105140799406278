import {
  GET_POSTCODE,
  FAILURE_GET_POSTCODE,
  FAILURE_UPDATE_POSTCODE,
  SUCCESS_GET_POSTCODE,
  UPDATE_POSTCODE_SUCCESS,
  IS_ADD_POSTCODE,
  ADD_POSTCODE_SUCCESS,
  FAILURE_ADD_POSTCODE,
  IS_DELETE_POSTCODE,
  DELETE_POSTCODE_SUCCESS,
  FAILURE_DELETE_POSTCODE,
  GET_DETAILS_POSTCODE,
  SUCCESS_GET_DETAILS_POSTCODE,
  FAILURE_GET_DETAILS_POSTCODE,
} from "../../constants/ActionTypes";

const initState = {
  list: [],
  total: 0,
  error: "",
  isGetDetail: false,
  postcodeSelected: null,
};

const postcodeReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_POSTCODE:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case SUCCESS_GET_POSTCODE:
      return Object.assign({}, state, {
        list: action.payload.data.data,
        total: action.payload.data.total,
        error: "",
        message: "",
      });
    case FAILURE_GET_POSTCODE:
      return Object.assign({}, state, {
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case GET_DETAILS_POSTCODE:
      return Object.assign({}, state, {
        isGetDetail: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_POSTCODE:
      return Object.assign({}, state, {
        isGetDetail: false,
        postcodeSelected: action.payload.data.data,
        error: "",
        message: "",
      });
    case FAILURE_GET_DETAILS_POSTCODE:
      return Object.assign({}, state, {
        isGetDetail: false,
        postcodeSelected: null,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case UPDATE_POSTCODE_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_POSTCODE:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.error.message,
        message: "",
      });
    case IS_ADD_POSTCODE:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case ADD_POSTCODE_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_ADD_POSTCODE:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_POSTCODE:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case DELETE_POSTCODE_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_DELETE_POSTCODE:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default postcodeReducer;
