import { GET_PROMO_CODE, SUCCESS_GET_PROMO_CODE, FAILURE_GET_PROMO_CODE, IS_ADD_NEW_PROMO_CODE, ADD_NEW_PROMO_CODE_SUCCESS, IS_UPDATE_PROMO_CODE, UPDATE_PROMO_CODE_SUCCESS} from 'constants/ActionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
const axios = require('axios');

function get_promo_code_api(params) {
    const { token, page, per_page, search } = params.params;
    let url = 'api/cms/promo_code/list?page=' + page + '&per_page=' + per_page;
    if (search){
        url = 'api/cms/promo_code/list?page=' + page + '&per_page=' + per_page + '&search=' + search;
    }
    return axios.request({
        method: 'get',
        url: url,
        headers: {'x-access-token' : token},
    });
}

function add_promo_code_api(params) {
    const { token, promo_code, discount_percent, start_date, end_date, minimum_price, } = params.params;
    return axios.request({
        method: 'post',
        url: 'api/cms/promo_code/add',
        data: {
            'promo_code' : promo_code,
            'discount_percent' : discount_percent,
            'start_date': start_date,
            'end_date': end_date,
            'minimum_price': minimum_price,
        },
        headers: {'x-access-token' : token},
    });
}

function update_promo_code_api(params) {
    const { token, promo_code, status, start_date, end_date, id, discount_percent, minimum_price } = params.params;
    return axios.request({
        method: 'put',
        url: '/api/cms/promo_code/update',
        data: {
            'id' : id,
            'promo_code': promo_code,
            'start_date': start_date,
            'end_date': end_date,
            'status': status,
            'discount_percent': discount_percent,
            'minimum_price': minimum_price,
        },
        headers: {'x-access-token': token},
    });
}

function* get_promo_code(params){
    try {
        const res = yield call(get_promo_code_api, params);
        yield put({
            type: SUCCESS_GET_PROMO_CODE,
            payload: res,
        });
    } catch (error) {
         yield put({
            type: FAILURE_GET_PROMO_CODE,
            payload: error,
        });
    }
}

function* add_promo_code(params){
    try {
        const res = yield call(add_promo_code_api, params);
        yield put({
            type: ADD_NEW_PROMO_CODE_SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: FAILURE_GET_PROMO_CODE,
            payload: error,
        });
    }
}

function* update_promo_code(params){
    try {
        const res = yield call(update_promo_code_api, params);
        yield put({
            type: UPDATE_PROMO_CODE_SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: FAILURE_GET_PROMO_CODE,
            payload: error,
        });
    }
}

export function* watchPromoCode(){
    yield takeEvery(GET_PROMO_CODE, get_promo_code);
    yield takeEvery(IS_ADD_NEW_PROMO_CODE, add_promo_code);
    yield takeEvery(IS_UPDATE_PROMO_CODE, update_promo_code);
}

