import {
  GET_ORDER_PRINT,
  SUCCESS_GET_ORDER_PRINT,
  FAILURE_GET_ORDER_PRINT,
  UPDATE_ORDER_PRINT
} from "constants/ActionTypes";

const initState = {
  isGetOrder: false,
  isPrinting: false,
  list: {},
  page: 1,
  per_page: 50,
  total: 0,
  error: ""
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_PRINT:
      return Object.assign({}, state, {
        list: action.params
      });
    case GET_ORDER_PRINT:
      return Object.assign({}, state, {
        isGetOrder: true,
        error: ""
      });
    case SUCCESS_GET_ORDER_PRINT:
      return Object.assign({}, state, {
        isGetOrder: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        error: ""
      });
    case FAILURE_GET_ORDER_PRINT:
      return Object.assign({}, state, {
        isGetOrder: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message
      });
    default:
      return state;
  }
};

export default orderReducer;
