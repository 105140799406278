import {
  GET_POSTCODE,
  FAILURE_UPDATE_POSTCODE,
  FAILURE_GET_POSTCODE,
  UPDATE_POSTCODE_SUCCESS,
  IS_UPDATE_POSTCODE,
  SUCCESS_GET_POSTCODE,
  ADD_POSTCODE_SUCCESS,
  FAILURE_ADD_POSTCODE,
  IS_ADD_POSTCODE,
  DELETE_POSTCODE_SUCCESS,
  FAILURE_DELETE_POSTCODE,
  IS_DELETE_POSTCODE,
  SUCCESS_GET_DETAILS_POSTCODE,
  FAILURE_GET_DETAILS_POSTCODE,
  GET_DETAILS_POSTCODE,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
const axios = require("axios");

function get_postcode_api(params) {
  const { page, per_page } = params;
  return axios.request({
    method: "get",
    url: "/api/cms/postcode/list",
    headers: { "x-access-token": localStorage.getItem("x-access-token") },
    params: {
      page,
      per_page,
      search: params ? params.search : "",
    },
  });
}

function get_detail_postcode_api(params) {
  const { id } = params.params;
  let url = `api/cms/postcode/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": localStorage.getItem("x-access-token") },
  });
}

function update_postcode_api(params) {
  return axios.request({
    method: "put",
    url: "/api/cms/postcode/update",
    data: params.params,
    headers: {
      "x-access-token": localStorage.getItem("x-access-token"),
    },
  });
}

function add_postcode_api(params) {
  return axios.request({
    method: "post",
    url: "/api/cms/postcode/add",
    data: params.params,
    headers: {
      "x-access-token": localStorage.getItem("x-access-token"),
    },
  });
}

function delete_postcode_api(params) {
  const { id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/postcode/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": localStorage.getItem("x-access-token") },
  });
}

function* get_postcode(params) {
  try {
    const res = yield call(get_postcode_api, params.params);
    yield put({
      type: SUCCESS_GET_POSTCODE,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_POSTCODE,
      error: err,
    });
  }
}

function* get_detail_postcode(params) {
  try {
    const res = yield call(get_detail_postcode_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_POSTCODE,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_POSTCODE,
      error: err,
    });
  }
}

function* update_postcode(params) {
  try {
    const res = yield call(update_postcode_api, params);
    yield put({
      type: UPDATE_POSTCODE_SUCCESS,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_UPDATE_POSTCODE,
      error: { message: "Update failed" },
    });
  }
}

function* add_postcode(params) {
  try {
    const res = yield call(add_postcode_api, params);
    yield put({
      type: ADD_POSTCODE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_POSTCODE,
      payload: error,
    });
  }
}

function* delete_postcode(params) {
  try {
    const res = yield call(delete_postcode_api, params);
    yield put({
      type: DELETE_POSTCODE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_POSTCODE,
      payload: error,
    });
  }
}

export function* watchPostcode() {
  yield takeEvery(GET_POSTCODE, get_postcode);
  yield takeEvery(IS_UPDATE_POSTCODE, update_postcode);
  yield takeEvery(IS_ADD_POSTCODE, add_postcode);
  yield takeEvery(IS_DELETE_POSTCODE, delete_postcode);
  yield takeEvery(GET_DETAILS_POSTCODE, get_detail_postcode);
}
