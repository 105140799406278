import {
  GET_TOPPING,
  FAILURE_UPDATE_TOPPING,
  FAILURE_GET_TOPPING,
  UPDATE_TOPPING_SUCCESS,
  IS_UPDATE_TOPPING,
  SUCCESS_GET_TOPPING,
  ADD_TOPPING_SUCCESS,
  FAILURE_ADD_TOPPING,
  IS_ADD_TOPPING,
  DELETE_TOPPING_SUCCESS,
  FAILURE_DELETE_TOPPING,
  IS_DELETE_TOPPING,
  TOPPING_UPDATE_PARTIAL_DATA_SUCCESS,
  TOPPING_UPDATE_PARTIAL_DATA,
  FAILURE_TOPPING_UPDATE_PARTIAL_DATA,
  SUCCESS_GET_DETAILS_TOPPING,
  FAILURE_GET_DETAILS_TOPPING,
  GET_DETAILS_TOPPING,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
const axios = require("axios");

function get_topping_api(params) {
  return axios.request({
    method: "get",
    url: "/api/cms/topping/list",
    headers: { "x-access-token": localStorage.getItem("x-access-token") },
    params: {
      page: 1,
      per_page: 1000,
      search: params ? params.search : undefined,
    },
  });
}

function get_detail_topping_api(params) {
  const { id, token } = params.params;
  let url = `api/cms/topping/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function update_topping_api(params) {
  return axios.request({
    method: "put",
    url: "/api/cms/topping/update",
    data: params.params,
    headers: {
      "x-access-token": localStorage.getItem("x-access-token"),
    },
  });
}

function add_topping_api(params) {
  return axios.request({
    method: "post",
    url: "/api/cms/topping/add",
    data: params.params,
    headers: {
      "x-access-token": localStorage.getItem("x-access-token"),
    },
  });
}

function delete_topping_api(params) {
  const { id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/topping/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": localStorage.getItem("x-access-token") },
  });
}

function* get_topping(params) {
  try {
    const res = yield call(get_topping_api, params.params);
    yield put({
      type: SUCCESS_GET_TOPPING,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_TOPPING,
      error: err,
    });
  }
}

function* get_detail_topping(params) {
  try {
    const res = yield call(get_detail_topping_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_TOPPING,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_TOPPING,
      error: err,
    });
  }
}

function* update_topping(params) {
  try {
    const res = yield call(update_topping_api, params);
    yield put({
      type: UPDATE_TOPPING_SUCCESS,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_UPDATE_TOPPING,
      error: err,
    });
  }
}

function* add_topping(params) {
  try {
    const res = yield call(add_topping_api, params);
    yield put({
      type: ADD_TOPPING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_TOPPING,
      payload: error,
    });
  }
}

function* delete_topping(params) {
  try {
    const res = yield call(delete_topping_api, params);
    yield put({
      type: DELETE_TOPPING_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_TOPPING,
      payload: error,
    });
  }
}

function* update_partial_data(payload) {
  try {
    const res = yield call(update_topping_api, payload);
    yield put({
      type: TOPPING_UPDATE_PARTIAL_DATA_SUCCESS,
      payload: { ...payload.params },
    });
  } catch (err) {
    yield put({
      type: FAILURE_TOPPING_UPDATE_PARTIAL_DATA,
      payload: err,
    });
  }
}

export function* watchTopping() {
  yield takeEvery(GET_TOPPING, get_topping);
  yield takeEvery(IS_UPDATE_TOPPING, update_topping);
  yield takeEvery(IS_ADD_TOPPING, add_topping);
  yield takeEvery(IS_DELETE_TOPPING, delete_topping);
  yield takeEvery(TOPPING_UPDATE_PARTIAL_DATA, update_partial_data);
  yield takeEvery(GET_DETAILS_TOPPING, get_detail_topping);
}
