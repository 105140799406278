import {
  GET_ORDER,
  SUCCESS_GET_ORDER,
  FAILURE_GET_ORDER,
  UPDATE_ORDER,
  SUCCESS_UPDATE_ORDER,
  FAILURE_UPDATE_ORDER,
  GET_DETAIL_ORDER,
  SUCCESS_GET_DETAIL_ORDER,
  FAILURE_GET_DETAIL_ORDER,
} from "constants/ActionTypes";

const initState = {
  isGetOrder: false,
  isUpdateOrder: false,
  list: [],
  page: 1,
  per_page: 50,
  total: 0,
  error: "",
  orderSelected: null,
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return Object.assign({}, state, {
        isGetOrder: true,
        error: "",
      });
    case SUCCESS_GET_ORDER:
      return Object.assign({}, state, {
        isGetOrder: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        error: "",
      });
    case FAILURE_GET_ORDER:
      return Object.assign({}, state, {
        isGetOrder: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
      });
    case GET_DETAIL_ORDER:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAIL_ORDER:
      return Object.assign({}, state, {
        orderSelected: action.payload.data.data,
        error: "",
        message: "",
      });
    case FAILURE_GET_DETAIL_ORDER:
      return Object.assign({}, state, {
        isUpdateOrder: false,
        orderSelected: null,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case UPDATE_ORDER:
      return Object.assign({}, state, {
        isUpdatePizza: true,
        error: "",
        message: "",
      });
    case SUCCESS_UPDATE_ORDER:
      return Object.assign({}, state, {
        isUpdateOrder: false,
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_ORDER:
      return Object.assign({}, state, {
        isUpdateOrder: false,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default orderReducer;
