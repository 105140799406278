import {
    IS_UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    FAILURE_UPDATE_NOTIFICATION,
    RESET_NOTIFICATION,
    IS_SEND_NOTIFICATION,
    SEND_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION,
    SUCCESS_GET_NOTIFICATION,
    FAILURE_GET_NOTIFICATION,
    IS_DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS, FAILURE_DELETE_NOTIFICATION,
} from 'constants/ActionTypes';

const initState = {
    'isUpdateNotification': false,
    'isSendNotification': false,
    'message': '',
    'error': '',
    list: [],
    isGetNotification: false,
    isDeleteNotification: false,
};

const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return Object.assign({}, state, {
                isGetNotification: true,
                error: '',
            });
        case SUCCESS_GET_NOTIFICATION:
            return Object.assign({}, state, {
                isGetNotification: false,
                list: action.payload.data.data,
                total: action.payload.data.total,
                page: action.payload.data.page,
                per_page: action.payload.data.per_page,
                error: '',
            });
        case FAILURE_GET_NOTIFICATION:
            return Object.assign({}, state, {
                isGetNotification: false,
                isDeleteNotification: false,
                list: [],
                error: action.payload.response.data.message || action.payload.message,
            });
        case IS_UPDATE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdateNotification: true,
                message: '',
                error: '',
            });
        case UPDATE_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                isUpdateNotification: false,
                message: 'Update notification success',
                error: '',
            });
        case RESET_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdateNotification: false,
                isDeleteNotification: false,
                message: '',
                error: '',
            });
        case IS_SEND_NOTIFICATION:
            return Object.assign({}, state, {
                isSendNotification: true,
                message: action.payload,
            });
        case SEND_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                isSendNotification: false,
                message: 'Send notification success',
            });
        case FAILURE_UPDATE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdateNotification: false,
                isSendNotification: false,
                isDeleteNotification: false,
                message: '',
                error: action.payload.response.data.message || action.payload.message,
            });
        case IS_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isDeleteNotification: true,
                error: '',
                message: '',
            });
        case DELETE_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                isDeleteNotification: false,
                message: 'Delete notification succeed. ',
                error: '',
            });
        case FAILURE_DELETE_NOTIFICATION:
            return Object.assign({}, state, {
                isUpdateNotification: false,
                isSendNotification: false,
                isDeleteNotification: false,
                list: [],
                error: action.payload.response.data.message || action.payload.message,
                message: '',
            });
        default:
            return state;
    }
};

export default notificationReducer;
