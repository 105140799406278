import {
  GET_DESSERT,
  SUCCESS_GET_DESSERT,
  FAILURE_GET_DESSERT,
  UPDATE_DESSERT_SUCCESS,
  FAILURE_UPDATE_DESSERT,
  IS_UPDATE_DESSERT,
  IS_ADD_DESSERT,
  ADD_DESSERT_SUCCESS,
  FAILURE_ADD_DESSERT,
  DELETE_DESSERT_SUCCESS,
  FAILURE_DELETE_DESSERT,
  IS_DELETE_DESSERT,
  SUCCESS_GET_DETAILS_DESSERT,
  FAILURE_GET_DETAILS_DESSERT,
  GET_DETAILS_DESSERT,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
const axios = require("axios");

function get_dessert_api(params) {
  const { per_page, page, search } = params.params;
  let url = "api/cms/dessert/list?page=" + page + "&per_page=" + per_page;
  if (search) {
    url =
      "api/cms/dessert/list?page=" +
      page +
      "&per_page=" +
      per_page +
      "&search=" +
      search;
  }
  const token = localStorage.getItem("x-access-token");
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function get_detail_dessert_api(params) {
  const { id, token } = params.params;
  let url = `api/cms/dessert/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function update_dessert_api(params) {
  const {
    token,
    image,
    name,
    description,
    price,
    id,
    status,
    index,
  } = params.params;

  const data = new FormData();

  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("name", name);
  data.append("description", description);
  data.append("price", price);
  data.append("id", id);
  data.append("status", status);
  data.append("index", index);

  return axios.request({
    method: "put",
    url: "/api/cms/dessert/update",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function add_dessert_api(params) {
  const { token, image, name, description, price } = params.params;

  const data = new FormData();
  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("name", name);
  data.append("description", description);
  data.append("price", price);

  return axios.request({
    method: "post",
    url: "/api/cms/dessert/add",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function delete_dessert_api(params) {
  const { token, id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/dessert/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": token },
  });
}

function* get_dessert(params) {
  try {
    const res = yield call(get_dessert_api, params);
    yield put({
      type: SUCCESS_GET_DESSERT,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DESSERT,
      error: err,
    });
  }
}

function* get_detail_dessert(params) {
  try {
    const res = yield call(get_detail_dessert_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_DESSERT,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_DESSERT,
      error: err,
    });
  }
}

function* update_dessert(params) {
  try {
    const res = yield call(update_dessert_api, params);
    yield put({
      type: UPDATE_DESSERT_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_UPDATE_DESSERT,
      payload: error,
    });
  }
}

function* add_dessert(params) {
  try {
    const res = yield call(add_dessert_api, params);
    yield put({
      type: ADD_DESSERT_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_DESSERT,
      payload: error,
    });
  }
}

function* delete_dessert(params) {
  try {
    const res = yield call(delete_dessert_api, params);
    yield put({
      type: DELETE_DESSERT_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_DESSERT,
      payload: error,
    });
  }
}

export function* watchDessert() {
  yield takeEvery(GET_DESSERT, get_dessert);
  yield takeEvery(IS_UPDATE_DESSERT, update_dessert);
  yield takeEvery(IS_ADD_DESSERT, add_dessert);
  yield takeEvery(IS_DELETE_DESSERT, delete_dessert);
  yield takeEvery(GET_DETAILS_DESSERT, get_detail_dessert);
}
