import _ from "lodash";
import {
  GET_TOPPING,
  FAILURE_GET_TOPPING,
  FAILURE_UPDATE_TOPPING,
  SUCCESS_GET_TOPPING,
  UPDATE_TOPPING_SUCCESS,
  IS_ADD_TOPPING,
  ADD_TOPPING_SUCCESS,
  FAILURE_ADD_TOPPING,
  IS_DELETE_TOPPING,
  DELETE_TOPPING_SUCCESS,
  FAILURE_DELETE_TOPPING,
  TOPPING_UPDATE_PARTIAL_DATA_SUCCESS,
  FAILURE_TOPPING_UPDATE_PARTIAL_DATA,
  GET_DETAILS_TOPPING,
  SUCCESS_GET_DETAILS_TOPPING,
  FAILURE_GET_DETAILS_TOPPING,
} from "../../constants/ActionTypes";

const initState = {
  list: [],
  total: 0,
  error: "",
  toppingSelected: null,
};

const pizzaReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOPPING:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case SUCCESS_GET_TOPPING:
      return Object.assign({}, state, {
        list: action.payload.data.data,
        total: action.payload.data.total,
        error: "",
        message: "",
      });
    case FAILURE_GET_TOPPING:
      return Object.assign({}, state, {
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case GET_DETAILS_TOPPING:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_TOPPING:
      return Object.assign({}, state, {
        toppingSelected: action.payload.data.data,
        error: "",
        message: "",
      });
    case FAILURE_GET_DETAILS_TOPPING:
      return Object.assign({}, state, {
        toppingSelected: null,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case UPDATE_TOPPING_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_TOPPING:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_ADD_TOPPING:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case ADD_TOPPING_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_ADD_TOPPING:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_TOPPING:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case DELETE_TOPPING_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_DELETE_TOPPING:
      return Object.assign({}, state, {
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });

    case TOPPING_UPDATE_PARTIAL_DATA_SUCCESS: {
      const newList = state.list.slice();

      _.forEach(newList, (item) => {
        if (item._id === action.payload.id) {
          item.isVegan = action.payload.isVegan;

          return false;
        }
      });

      return Object.assign({}, state, {
        list: newList,
      });
    }

    case FAILURE_TOPPING_UPDATE_PARTIAL_DATA: {
      return Object.assign({}, state, {
        error: action.payload.response.data.message || action.payload.message,
      });
    }

    default:
      return state;
  }
};

export default pizzaReducer;
