import {
  GET_REPORT,
  SUCCESS_GET_REPORT,
  FAILURE_GET_REPORT
} from "constants/ActionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
const axios = require("axios");

function get_report_api(params) {
  const { date, token, startDate, endDate } = params.params;
  let url = `api/cms/report/report-by-time?date=${date}&start_date=${startDate}&end_date=${endDate}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token }
  });
}

function* get_report(params) {
  try {
    const res = yield call(get_report_api, params);
    yield put({
      type: SUCCESS_GET_REPORT,
      payload: res
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORT,
      error: err
    });
  }
}

export function* watchReport() {
  yield takeEvery(GET_REPORT, get_report);
}
