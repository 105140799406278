import {
  GET_DRINK,
  SUCCESS_GET_DRINK,
  FAILURE_GET_DRINK,
  ADD_DRINK_SUCCESS,
  FAILURE_ADD_DRINK,
  IS_ADD_DRINK,
  DELETE_DRINK_SUCCESS,
  FAILURE_DELETE_DRINK,
  IS_DELETE_DRINK,
  SUCCESS_GET_DETAILS_DRINK,
  FAILURE_GET_DETAILS_DRINK,
  GET_DETAILS_DRINK,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  FAILURE_UPDATE_DRINK,
  IS_UPDATE_DRINK,
  UPDATE_DRINK_SUCCESS,
} from "../../constants/ActionTypes";
const axios = require("axios");

function get_drink_api(params) {
  const { per_page, page, search, token } = params.params;
  let url = "api/cms/drink/list?page=" + page + "&per_page=" + per_page;
  if (search) {
    url =
      "api/cms/drink/list?page=" +
      page +
      "&per_page=" +
      per_page +
      "&search=" +
      search;
  }
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function get_detail_drink_api(params) {
  const { id, token } = params.params;
  let url = `api/cms/drink/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function update_drink_api(params) {
  const {
    token,
    image,
    name,
    description,
    price,
    id,
    status,
    index,
  } = params.params;

  const data = new FormData();

  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("name", name);
  data.append("description", description);
  data.append("price", price);
  data.append("id", id);
  data.append("status", status);
  data.append("index", index);

  return axios.request({
    method: "put",
    url: "/api/cms/drink/update",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function add_drink_api(params) {
  const { token, image, name, description, price } = params.params;

  const data = new FormData();
  if (typeof image === "string") {
    data.append("image", image);
  } else {
    data.append("file", image);
  }

  data.append("name", name);
  data.append("description", description);
  data.append("price", price);

  return axios.request({
    method: "post",
    url: "/api/cms/drink/add",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function delete_drink_api(params) {
  const { token, id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/drink/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": token },
  });
}

function* get_drink(params) {
  try {
    const res = yield call(get_drink_api, params);
    yield put({
      type: SUCCESS_GET_DRINK,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DRINK,
      error: err,
    });
  }
}

function* get_detail_drink(params) {
  try {
    const res = yield call(get_detail_drink_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_DRINK,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_DRINK,
      error: err,
    });
  }
}

function* update_drink(params) {
  try {
    const res = yield call(update_drink_api, params);
    yield put({
      type: UPDATE_DRINK_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_UPDATE_DRINK,
      payload: error,
    });
  }
}

function* add_drink(params) {
  try {
    const res = yield call(add_drink_api, params);
    yield put({
      type: ADD_DRINK_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_DRINK,
      payload: error,
    });
  }
}

function* delete_drink(params) {
  try {
    const res = yield call(delete_drink_api, params);
    yield put({
      type: DELETE_DRINK_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_DRINK,
      payload: error,
    });
  }
}

export function* watchDrink() {
  yield takeEvery(GET_DRINK, get_drink);
  yield takeEvery(IS_UPDATE_DRINK, update_drink);
  yield takeEvery(IS_ADD_DRINK, add_drink);
  yield takeEvery(IS_DELETE_DRINK, delete_drink);
  yield takeEvery(GET_DETAILS_DRINK, get_detail_drink);
}
