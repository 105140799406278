import {
  FAILURE_GET_BAKERY_ORDER_PRINT,
  GET_BAKERY_ORDER_PRINT,
  SUCCESS_GET_BAKERY_ORDER_PRINT
} from "constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";

// const axios = require("axios");

function get_bakery_order_api(params) {
  // const { page, per_page, search } = params.params;

  // let url = "api/cms/order/list?page=" + page + "&per_page=" + per_page;
  //
  // if (search) {
  //   url =
  //     "api/cms/order/list?page=" +
  //     page +
  //     "&per_page=" +
  //     per_page +
  //     "&search=" +
  //     search;
  // }
  // return database.ref("orders").on("value");
  // database.ref("orders").on("value", snapshot => {
  //   return snapshot;
  // });
  return Promise;
  // return axios.request({
  //   method: "get",
  //   url: url,
  //   headers: { "x-access-token": token }
  // });
}

function* get_bakery_order_print(params) {
  try {
    const res = yield call(get_bakery_order_api, params);
    yield put({
      type: SUCCESS_GET_BAKERY_ORDER_PRINT,
      payload: res
    });
  } catch (error) {
    yield put({
      type: FAILURE_GET_BAKERY_ORDER_PRINT,
      payload: error
    });
  }
}

export function* watchBakeryOrderPrint() {
  yield takeEvery(GET_BAKERY_ORDER_PRINT, get_bakery_order_print);
}
