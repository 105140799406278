import {
  GET_REPORT_BY_MK,
  SUCCESS_GET_REPORT_BY_MK,
  FAILURE_GET_REPORT_BY_MK,
} from "constants/ActionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
const axios = require("axios");

function get_report_by_mk_api(params) {
  const { date, token, startDate, endDate } = params.params;
  let url = `api/cms/report/report-by-mk?date=${date}&start_date=${startDate}&end_date=${endDate}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function* get_report_by_mk(params) {
  try {
    const res = yield call(get_report_by_mk_api, params);
    yield put({
      type: SUCCESS_GET_REPORT_BY_MK,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORT_BY_MK,
      error: err,
    });
  }
}

export function* watchReportByMK() {
  yield takeEvery(GET_REPORT_BY_MK, get_report_by_mk);
}
