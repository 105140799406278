import {
  GET_DRINK,
  SUCCESS_GET_DRINK,
  FAILURE_GET_DRINK,
  IS_UPDATE_DRINK,
  UPDATE_DRINK_SUCCESS,
  FAILURE_UPDATE_DRINK,
  IS_DELETE_DRINK,
  DELETE_DRINK_SUCCESS,
  FAILURE_DELETE_DRINK,
  GET_DETAILS_DRINK,
  SUCCESS_GET_DETAILS_DRINK,
  FAILURE_GET_DETAILS_DRINK,
} from "constants/ActionTypes";
import {
  ADD_DRINK_SUCCESS,
  FAILURE_ADD_DRINK,
  IS_ADD_DRINK,
} from "../../constants/ActionTypes";

const initState = {
  isGetDrink: false,
  isGetDetailDrink: false,
  isUpdateDrink: false,
  isAddDrink: false,
  isDeleteDrink: false,
  list: [],
  page: 1,
  per_page: 50,
  total: 0,
  error: "",
  drinkSelected: null,
};

const drinkReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DRINK:
      return Object.assign({}, state, {
        isGetDrink: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DRINK:
      return Object.assign({}, state, {
        isGetDrink: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        error: "",
        message: "",
      });
    case FAILURE_GET_DRINK:
      return Object.assign({}, state, {
        isGetDrink: false,
        isUpdateDrink: false,
        isDeleteDrink: false,
        isAddDrink: false,
        isGetDetailDrink: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case GET_DETAILS_DRINK:
      return Object.assign({}, state, {
        isGetDetailDrink: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_DRINK:
      return Object.assign({}, state, {
        isGetDetailDrink: false,
        drinkSelected: action.payload.data.data,
      });
    case FAILURE_GET_DETAILS_DRINK:
      return Object.assign({}, state, {
        isGetDrink: false,
        isUpdateDrink: false,
        isDeleteDrink: false,
        isAddDrink: false,
        isGetDetailDrink: false,
        drinkSelected: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_UPDATE_DRINK:
      return Object.assign({}, state, {
        isUpdateDrink: true,
        error: "",
        message: "",
      });
    case UPDATE_DRINK_SUCCESS:
      return Object.assign({}, state, {
        isUpdateDrink: false,
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_DRINK:
      return Object.assign({}, state, {
        isUpdateDrink: false,
        isGetDrink: false,
        isAddDrink: false,
        isDeleteDrink: false,
        isGetDetailDrink: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_ADD_DRINK:
      return Object.assign({}, state, {
        isAddDrink: true,
        error: "",
        message: "",
      });
    case ADD_DRINK_SUCCESS:
      return Object.assign({}, state, {
        isAddDrink: false,
        message: true,
        error: "",
      });
    case FAILURE_ADD_DRINK:
      return Object.assign({}, state, {
        isUpdateDrink: false,
        isGetDrink: false,
        isAddDrink: false,
        isDeleteDrink: false,
        isGetDetailDrink: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_DRINK:
      return Object.assign({}, state, {
        isDeleteDrink: true,
        error: "",
        message: "",
      });
    case DELETE_DRINK_SUCCESS:
      return Object.assign({}, state, {
        isDeleteDrink: false,
        message: true,
        error: "",
      });
    case FAILURE_DELETE_DRINK:
      return Object.assign({}, state, {
        isUpdateDrink: false,
        isGetDrink: false,
        isAddDrink: false,
        isDeleteDrink: false,
        isGetDetailDrink: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default drinkReducer;
