/** @flow */
import {
  GET_USER,
  SUCCESS_GET_USER,
  FAILURE_GET_USER,
  CHANGE_PASSWORD_USER,
  IS_CHANGE_PASS,
  IS_DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_DETAILS_USER,
  SUCCESS_GET_DETAILS_USER,
  FAILURE_GET_DETAILS_USER,
} from "constants/ActionTypes";

type State = {
  isGetUsers: boolean,
  list: Array<any>,
  total: Number,
  page: Number,
  per_page: Number,
  error: "",
  isDeleteUser: false,
};

const initState = {
  isGetUsers: false,
  isChangePass: false,
  list: [],
  total: 0,
  page: 1,
  per_page: 50,
  message: "",
  userSelected: null,
};

const userReducer = (state: State = initState, action) => {
  switch (action.type) {
    case GET_USER:
      return Object.assign({}, state, {
        isGetUsers: true,
        error: "",
        message: "",
      });
    case IS_CHANGE_PASS:
      return Object.assign({}, state, {
        isChangePass: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_USER:
      return Object.assign({}, state, {
        isGetUsers: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        message: "",
        error: "",
      });
    case GET_DETAILS_USER:
      return Object.assign({}, state, {
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_USER:
      return Object.assign({}, state, {
        userSelected: action.payload.data.data,
        error: "",
        message: "",
      });
    case FAILURE_GET_DETAILS_USER:
      return Object.assign({}, state, {
        isGetUsers: false,
        message: "",
        error: "",
        userSelected: null,
      });
    case CHANGE_PASSWORD_USER:
      return Object.assign({}, state, {
        isChangePass: false,
        message: action.message,
        error: "",
      });
    case IS_DELETE_USER:
      return Object.assign({}, state, {
        isDeleteUser: true,
        error: "",
        message: "",
      });
    case DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        isDeleteUser: true,
        error: "",
        message: "succeed",
      });
    case FAILURE_GET_USER:
      return Object.assign({}, state, {
        isGetUsers: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
        isDeleteUser: false,
      });
    default:
      return state;
  }
};

export default userReducer;
