/** @flow */

import authReducer from "./auth/reducer";
import userReducer from "./user/reducer";
import promoCodeReducer from "./promo_code/reducer";
import orderReducer from "./order/reducer";
import orderPrintReducer from "./order_print/reducer";
import bakeryOrderPrintReducer from "./bakery_order_print/reducer";
import notificationReducer from "./notification/reducer";
import { combineReducers } from "redux";
import drinkReducer from "./drink/reducer";
import dessertReducer from "./dessert/reducer";
import pizzaReducer from "./pizza/reducer";
import reportReducer from "./report/reducer";
import topping from "./topping/reducer";
import bannerReducer from "./banner/reducer";
import reportByMKReducer from "./report_by_mk/reducer";
import reportsReducer from "./reports/reducer";
import reportByLiveAndBakeryReducer from "./report_by_live_and_bakery/reducer";
import postcodeReducer from "./postcode/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  report: reportReducer,
  reports: reportsReducer,
  user: userReducer,
  promo_code: promoCodeReducer,
  order: orderReducer,
  order_print: orderPrintReducer,
  bakery_order_print: bakeryOrderPrintReducer,
  notification: notificationReducer,
  drink: drinkReducer,
  dessert: dessertReducer,
  pizza: pizzaReducer,
  topping,
  banner: bannerReducer,
  reportByMK: reportByMKReducer,
  reportByLiveAndBakery: reportByLiveAndBakeryReducer,
  postcode: postcodeReducer,
});

export default rootReducer;
