import {
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  FAILURE_UPDATE_BANNER,
  SUCCESS_GET_BANNER,
  FAILURE_GET_BANNER,
  GET_BANNER,
  DELETE_BANNER_SUCCESS,
  FAILURE_DELETE_BANNER,
  IS_DELETE_BANNER,
  UPDATE_INDEX_BANNER_SUCCESS,
  FAILURE_UPDATE_INDEX_BANNER,
  UPDATE_INDEX_BANNER,
  SUCCESS_ADD_BANNER,
  FAILURE_ADD_BANNER,
  ADD_BANNER,
} from "../../constants/ActionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import { isEmpty } from "lodash";

const axios = require("axios");

function get_banner_api() {
  let url = "api/cms/banner/get";
  const token = localStorage.getItem("x-access-token");
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function* get_banner(params) {
  try {
    const res = yield call(get_banner_api);
    const { callback } = params.action;
    if (callback) {
      callback(res.data.data);
    }
    yield put({
      type: SUCCESS_GET_BANNER,
      payload: res,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAILURE_GET_BANNER,
      error: err,
    });
  }
}

function update_banner_api(params) {
  const { token, image, status, index, id } = params.params;

  const data = new FormData();
  data.append("id", id);
  if (typeof image === "string") {
    data.append("image", image);
  } else if (!isEmpty(image)) {
    data.append("file", image);
  }

  data.append("status", status);

  if (!isEmpty(index)) {
    data.append("index", index);
  }

  return axios.request({
    method: "put",
    url: "/api/cms/banner/update",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function* update_banner(params) {
  try {
    const res = yield call(update_banner_api, params);
    yield put({
      type: UPDATE_BANNER_SUCCESS,
      payload: res,
    });
    yield call(get_banner, {
      type: "GET_BANNER",
      action: { callback: params.params.callback },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: FAILURE_UPDATE_BANNER,
      payload: error,
    });
  }
}

function update_index_banner_api(params) {
  let { token, data } = params.params;
  data = data.map((item) => ({ ...item, id: item._id }));
  return axios.request({
    method: "put",
    url: "/api/cms/banner/update-index",
    data: {
      banners: data,
    },
    headers: { "x-access-token": token },
  });
}

function* update_index_banner(params) {
  try {
    const res = yield call(update_index_banner_api, params);
    yield put({
      type: UPDATE_INDEX_BANNER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_UPDATE_INDEX_BANNER,
      payload: error,
    });
  }
}

function delete_banner_api(params) {
  const { token, id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/banner/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": token },
  });
}

function* delete_banner(params) {
  try {
    let res = yield call(delete_banner_api, params);
    yield put({
      type: DELETE_BANNER_SUCCESS,
      payload: res,
    });
    yield call(get_banner, {
      type: "GET_BANNER",
      action: { callback: params.params.callback },
    });
  } catch (error) {
    yield put({
      type: FAILURE_DELETE_BANNER,
      payload: error,
    });
  }
}

function add_banner_api(params) {
  const { token, image, status, index } = params.params;

  const data = new FormData();
  if (typeof image === "string") {
    data.append("url", image);
  } else {
    data.append("file", image);
  }

  data.append("status", status);
  data.append("index", index);

  return axios.request({
    method: "post",
    url: "/api/cms/banner/add",
    data,
    headers: {
      "x-access-token": token,
      "Content-Type": "multipart/form-data;",
    },
  });
}

function* add_banner(params) {
  try {
    const res = yield call(add_banner_api, params);
    yield put({
      type: SUCCESS_ADD_BANNER,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_ADD_BANNER,
      payload: error,
    });
  }
}

export function* watchBanner() {
  yield takeEvery(UPDATE_BANNER, update_banner);
  yield takeEvery(GET_BANNER, get_banner);
  yield takeEvery(UPDATE_INDEX_BANNER, update_index_banner);
  yield takeEvery(IS_DELETE_BANNER, delete_banner);
  yield takeEvery(ADD_BANNER, add_banner);
}
