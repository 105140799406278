import {
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  FAILURE_UPDATE_BANNER,
  GET_BANNER,
  SUCCESS_GET_BANNER,
  FAILURE_GET_BANNER,
  IS_DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  FAILURE_DELETE_BANNER,
  UPDATE_INDEX_BANNER,
  UPDATE_INDEX_BANNER_SUCCESS,
  FAILURE_UPDATE_INDEX_BANNER,
  ADD_BANNER,
  SUCCESS_ADD_BANNER,
  FAILURE_ADD_BANNER,
} from "constants/ActionTypes";

const initState = {
  isAddBanner: false,
  error: "",
  message: "",
  isGetBanner: false,
  isUpdateBanner: false,
  isDeleteBanner: false,
  isUpdateIndexBanner: false,
  list: [],
};

const bannerReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_BANNER:
      return Object.assign({}, state, {
        isAddBanner: true,
        error: "",
        message: "",
      });
    case SUCCESS_ADD_BANNER:
      return Object.assign({}, state, {
        isAddBanner: false,
        message: true,
        error: "",
      });
    case FAILURE_ADD_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        isUpdateBanner: false,
        isAddBanner: false,
        isDeleteBanner: false,
        isUpdateIndexBanner: false,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case GET_BANNER:
      return Object.assign({}, state, {
        isGetBanner: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        list: action.payload.data.data,
        error: "",
      });
    case FAILURE_GET_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        isUpdateBanner: false,
        isAddBanner: false,
        isDeleteBanner: false,
        isUpdateIndexBanner: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
      });
    case UPDATE_BANNER:
      return Object.assign({}, state, {
        isUpdateBanner: true,
        error: "",
        message: "",
      });
    case UPDATE_BANNER_SUCCESS:
      return Object.assign({}, state, {
        isUpdateBanner: false,
        error: "",
        message: true,
      });
    case FAILURE_UPDATE_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        isUpdateBanner: false,
        isAddBanner: false,
        isDeleteBanner: false,
        isUpdateIndexBanner: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case UPDATE_INDEX_BANNER:
      return Object.assign({}, state, {
        isUpdateIndexBanner: true,
        error: "",
        message: "",
      });
    case UPDATE_INDEX_BANNER_SUCCESS:
      return Object.assign({}, state, {
        isUpdateIndexBanner: false,
        error: "",
        list: action.payload.data.data,
      });
    case FAILURE_UPDATE_INDEX_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        isUpdateBanner: false,
        isAddBanner: false,
        isDeleteBanner: false,
        isUpdateIndexBanner: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_BANNER:
      return Object.assign({}, state, {
        isDeleteBanner: true,
        error: "",
        message: "",
      });
    case DELETE_BANNER_SUCCESS:
      return Object.assign({}, state, {
        isDeleteBanner: false,
        message: true,
        error: "",
      });
    case FAILURE_DELETE_BANNER:
      return Object.assign({}, state, {
        isGetBanner: false,
        isUpdateBanner: false,
        isAddBanner: false,
        isDeleteBanner: false,
        isUpdateIndexBanner: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default bannerReducer;
