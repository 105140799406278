import {
  GET_REPORT_BY_LIVE_AND_BAKERY,
  SUCCESS_GET_REPORT_BY_LIVE_AND_BAKERY,
  FAILURE_GET_REPORT_BY_LIVE_AND_BAKERY,
} from "constants/ActionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
const axios = require("axios");

function get_report_by_live_and_bakery_api(params) {
  const { date, token, startDate, endDate } = params.params;
  let url = `api/cms/report/report-by-live-and-bakery?date=${date}&start_date=${startDate}&end_date=${endDate}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function* get_report_by_live_and_bakery(params) {
  try {
    const res = yield call(get_report_by_live_and_bakery_api, params);
    yield put({
      type: SUCCESS_GET_REPORT_BY_LIVE_AND_BAKERY,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_REPORT_BY_LIVE_AND_BAKERY,
      error: err,
    });
  }
}

export function* watchReportByLiveAndBakery() {
  yield takeEvery(GET_REPORT_BY_LIVE_AND_BAKERY, get_report_by_live_and_bakery);
}
