import { all } from "redux-saga/effects";
import { watchAuth } from "./auth/saga";
import { watchUser } from "./user/saga";
import { watchPromoCode } from "./promo_code/saga";
import { watchOrder } from "./order/saga";
import { watchOrderPrint } from "./order_print/saga";
import { watchBakeryOrderPrint } from "./bakery_order_print/saga";
import { watchNotification } from "./notification/saga";
import { watchDrink } from "./drink/saga";
import { watchDessert } from "./dessert/saga";
import { watchPizza } from "./pizza/saga";
import { watchReport } from "./report/saga";
import { watchReports, watchReportsByAmountPaid, watchReportsUser, watchReportsUserRegister } from "./reports/saga";

import { watchTopping } from "./topping/saga";
import { watchBanner } from "./banner/saga";
import { watchReportByMK } from "./report_by_mk/saga";
import { watchReportByLiveAndBakery } from "./report_by_live_and_bakery/saga";
import { watchPostcode } from "./postcode/saga";

export default function* rootSaga() {
  yield all([
    watchReports(),
    watchReportsByAmountPaid(),
    watchReportsUser(),
    watchReportsUserRegister(),
    watchAuth(),
    watchUser(),
    watchPromoCode(),
    watchOrder(),
    watchOrderPrint(),
    watchNotification(),
    watchDrink(),
    watchDessert(),
    watchPizza(),
    watchBakeryOrderPrint(),
    watchReport(),
    watchTopping(),
    watchBanner(),
    watchReportByMK(),
    watchReportByLiveAndBakery(),
    watchPostcode(),
  ]);
}
