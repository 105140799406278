/** @flow */

import { GET_LOGIN, LOGIN_SUCCESS_USER, LOGIN_ERROR_USER, LOGIN } from '../../constants/ActionTypes';

type State = {
    isGetProfile: boolean,
    isLogedIn: boolean,
    user: Object,
    errorLogin: string
}

const initState = {
    isGetProfile: false,
    isLogedIn: false,
    user: null,
    errorLogin: '',
};

const authReducer = (state: State = initState, action: any) =>{
    switch (action.type) {
        case GET_LOGIN:
            return Object.assign({}, state,{
                isGetProfile: true,
                isLogedIn: false,
                errorLogin: '',
            });
        case LOGIN_SUCCESS_USER:
            return Object.assign({}, state,{
                isGetProfile: false,
                isLogedIn: true,
                user: action.payload.data,
                errorLogin: '',
            });
        case LOGIN_ERROR_USER:
            return Object.assign({}, state, {
                errorLogin: action.payload.response.data.message || action.payload.message,
                isLogedIn: false,
            });
        case LOGIN:
            return Object.assign({}, state, {
                isLogedIn: true,
            });
        default:
            return state;
    }
};

export default authReducer;
