import {
  GET_USER,
  SUCCESS_GET_USER,
  FAILURE_GET_USER,
  CHANGE_PASSWORD_USER,
  IS_CHANGE_PASS,
  DELETE_USER_SUCCESS,
  IS_DELETE_USER,
  SUCCESS_GET_DETAILS_USER,
  FAILURE_GET_DETAILS_USER,
  GET_DETAILS_USER,
} from "constants/ActionTypes";
import { takeEvery, call, put } from "redux-saga/effects";
const axios = require("axios");

function get_list_user_api(params) {
  const { token, page, per_page, search } = params.params;
  let url = "api/cms/user/list?page=" + page + "&per_page=" + per_page;
  if (search) {
    url =
      "api/cms/user/list?page=" +
      page +
      "&per_page=" +
      per_page +
      "&search=" +
      search;
  }
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function get_detail_user_api(params) {
  const { id } = params.params;
  const token = localStorage.getItem("x-access-token");
  let url = `api/cms/user/detail/${id}`;
  return axios.request({
    method: "get",
    url: url,
    headers: { "x-access-token": token },
  });
}

function* get_detail_user(params) {
  try {
    const res = yield call(get_detail_user_api, params);
    yield put({
      type: SUCCESS_GET_DETAILS_USER,
      payload: res,
    });
  } catch (err) {
    yield put({
      type: FAILURE_GET_DETAILS_USER,
      error: err,
    });
  }
}

function change_password_user_api(params) {
  const { token, user_id, password } = params.params;
  return axios.request({
    method: "put",
    url: "api/cms/user/change_password",
    data: {
      user_id: user_id,
      password: password,
    },
    headers: { "x-access-token": token },
  });
}

function delete_user_api(params) {
  const { token, id } = params.params;
  return axios.request({
    method: "delete",
    url: "/api/cms/user/delete",
    data: {
      id: id,
    },
    headers: { "x-access-token": token },
  });
}

function* get_list_user(params) {
  try {
    const res = yield call(get_list_user_api, params);
    yield put({
      type: SUCCESS_GET_USER,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_GET_USER,
      payload: error,
    });
  }
}

function* change_password_user(params) {
  try {
    const res = yield call(change_password_user_api, params);
    yield put({
      type: CHANGE_PASSWORD_USER,
      message: res.data.success,
    });
  } catch (error) {
    yield put({
      type: FAILURE_GET_USER,
      payload: error,
    });
  }
}

function* delete_user(params) {
  try {
    const res = yield call(delete_user_api, params);
    yield put({
      type: DELETE_USER_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: FAILURE_GET_USER,
      payload: error,
    });
  }
}

export function* watchUser() {
  yield takeEvery(GET_USER, get_list_user);
  yield takeEvery(IS_CHANGE_PASS, change_password_user);
  yield takeEvery(IS_DELETE_USER, delete_user);
  yield takeEvery(GET_DETAILS_USER, get_detail_user);
}
