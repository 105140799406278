import {
  GET_DESSERT,
  SUCCESS_GET_DESSERT,
  FAILURE_GET_DESSERT,
  IS_UPDATE_DESSERT,
  UPDATE_DESSERT_SUCCESS,
  FAILURE_UPDATE_DESSERT,
  IS_ADD_DESSERT,
  ADD_DESSERT_SUCCESS,
  FAILURE_ADD_DESSERT,
  IS_DELETE_DESSERT,
  DELETE_DESSERT_SUCCESS,
  FAILURE_DELETE_DESSERT,
  GET_DETAILS_DESSERT,
  SUCCESS_GET_DETAILS_DESSERT,
  FAILURE_GET_DETAILS_DESSERT,
} from "constants/ActionTypes";

const initState = {
  isGetDetailDessert: false,
  isGetDessert: false,
  isUpdateDessert: false,
  isAddDessert: false,
  isDeleteDessert: false,
  list: [],
  page: 1,
  per_page: 50,
  total: 0,
  error: "",
  dessertSelected: null,
};

const dessertReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DESSERT:
      return Object.assign({}, state, {
        isGetDessert: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DESSERT:
      return Object.assign({}, state, {
        isGetDessert: false,
        list: action.payload.data.data,
        total: action.payload.data.total,
        page: action.payload.data.page,
        per_page: action.payload.data.per_page,
        error: "",
      });
    case FAILURE_GET_DESSERT:
      return Object.assign({}, state, {
        isUpdateDessert: false,
        isGetDessert: false,
        isAddDessert: false,
        isDeleteDessert: false,
        isGetDetailDessert: false,
        list: [],
        error: action.payload.response.data.message || action.payload.message,
      });
    case GET_DETAILS_DESSERT:
      return Object.assign({}, state, {
        isGetDetailDessert: true,
        error: "",
        message: "",
      });
    case SUCCESS_GET_DETAILS_DESSERT:
      return Object.assign({}, state, {
        isGetDetailDessert: false,
        dessertSelected: action.payload.data.data,
      });
    case FAILURE_GET_DETAILS_DESSERT:
      return Object.assign({}, state, {
        isGetDrink: false,
        isUpdateDrink: false,
        isDeleteDrink: false,
        isAddDrink: false,
        isGetDetailDessert: false,
        dessertSelected: null,
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_UPDATE_DESSERT:
      return Object.assign({}, state, {
        isUpdateDessert: true,
        error: "",
        message: "",
      });
    case UPDATE_DESSERT_SUCCESS:
      return Object.assign({}, state, {
        message: true,
        error: "",
      });
    case FAILURE_UPDATE_DESSERT:
      return Object.assign({}, state, {
        isUpdateDessert: false,
        isGetDessert: false,
        isAddDessert: false,
        isDeleteDessert: false,
        isGetDetailDessert: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_ADD_DESSERT:
      return Object.assign({}, state, {
        isAddDessert: true,
        error: "",
        message: "",
      });
    case ADD_DESSERT_SUCCESS:
      return Object.assign({}, state, {
        isAddDessert: false,
        message: true,
        error: "",
      });
    case FAILURE_ADD_DESSERT:
      return Object.assign({}, state, {
        isUpdateDessert: false,
        isGetDessert: false,
        isAddDessert: false,
        isDeleteDessert: false,
        isGetDetailDessert: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    case IS_DELETE_DESSERT:
      return Object.assign({}, state, {
        isDeleteDessert: true,
        error: "",
        message: "",
      });
    case DELETE_DESSERT_SUCCESS:
      return Object.assign({}, state, {
        isDeleteDessert: false,
        message: true,
        error: "",
      });
    case FAILURE_DELETE_DESSERT:
      return Object.assign({}, state, {
        isUpdateDessert: false,
        isGetDessert: false,
        isAddDessert: false,
        isDeleteDessert: false,
        isGetDetailDessert: false,
        list: [],
        data: [],
        error: action.payload.response.data.message || action.payload.message,
        message: "",
      });
    default:
      return state;
  }
};

export default dessertReducer;
