/** @flow */

//action login
export const GET_LOGIN = "GET_LOGIN";
export const LOGIN_SUCCESS_USER = "LOGIN_SUCCESS_USER";
export const LOGIN_ERROR_USER = "LOGIN_ERROR_USER";
export const LOGIN = "LOGIN";

//action report
export const GET_REPORTS_USER = "GET_REPORTS_USER";
export const SUCCESS_GET_REPORTS_USER = "SUCCESS_GET_REPORTS_USER";
export const FAILURE_GET_REPORTS_USER = "FAILURE_GET_REPORTS_USER";

export const GET_REPORTS_USER_REGISTER = "GET_REPORTS_USER_REGISTER";
export const SUCCESS_GET_REPORTS_USER_REGISTER = "SUCCESS_GET_REPORTS_USER_REGISTER";
export const FAILURE_GET_REPORTS_USER_REGISTER = "FAILURE_GET_REPORTS_USER_REGISTER";

export const GET_REPORTS = "GET_REPORTS";
export const SUCCESS_GET_REPORTS = "SUCCESS_GET_REPORTS";
export const FAILURE_GET_REPORTS = "FAILURE_GET_REPORTS";
export const GET_REPORTS_BY_AMOUNT_PAID = "GET_REPORTS_BY_AMOUNT_PAID";
export const SUCCESS_GET_REPORTS_BY_AMOUNT_PAID =
  "SUCCESS_GET_REPORTS_BY_AMOUNT_PAID";
export const FAILURE_GET_REPORTS_BY_AMOUNT_PAID =
  "FAILURE_GET_REPORTS_BY_AMOUNT_PAID";
//action report
export const GET_REPORT = "GET_REPORT";
export const SUCCESS_GET_REPORT = "SUCCESS_GET_REPORT";
export const FAILURE_GET_REPORT = "FAILURE_GET_REPORT";

//action report by MK
export const GET_REPORT_BY_MK = "GET_REPORT_BY_MK";
export const SUCCESS_GET_REPORT_BY_MK = "SUCCESS_GET_REPORT_BY_MK";
export const FAILURE_GET_REPORT_BY_MK = "FAILURE_GET_REPORT_BY_MK";

//action report
export const GET_REPORT_BY_LIVE_AND_BAKERY = "GET_REPORT_BY_LIVE_AND_BAKERY";
export const SUCCESS_GET_REPORT_BY_LIVE_AND_BAKERY =
  "SUCCESS_GET_REPORT_BY_LIVE_AND_BAKERY";
export const FAILURE_GET_REPORT_BY_LIVE_AND_BAKERY =
  "FAILURE_GET_REPORT_BY_LIVE_AND_BAKERY";

//action user
export const GET_USER = "GET_USER";
export const SUCCESS_GET_USER = "SUCCESS_GET_USER";
export const FAILURE_GET_USER = "FAILURE_GET_USER";
export const CHANGE_PASSWORD_USER = "CHANGE_PASSWORD_USER";
export const IS_CHANGE_PASS = "IS_CHANGE_PASS";
export const IS_DELETE_USER = "IS_DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_DETAILS_USER = "GET_DETAILS_USER";
export const SUCCESS_GET_DETAILS_USER = "SUCCESS_GET_DETAILS_USER";
export const FAILURE_GET_DETAILS_USER = "FAILURE_GET_DETAILS_USER";

//action promo code
export const GET_PROMO_CODE = "GET_PROMO_CODE";
export const SUCCESS_GET_PROMO_CODE = "SUCCESS_GET_PROMO_CODE";
export const FAILURE_GET_PROMO_CODE = "FAILURE_GET_PROMO_CODE";
export const ADD_NEW_PROMO_CODE_SUCCESS = "ADD_NEW_PROMO_CODE_SUCCESS";
export const IS_ADD_NEW_PROMO_CODE = "IS_ADD_NEW_PROMO_CODE";
export const IS_UPDATE_PROMO_CODE = "IS_UPDATE_PROMO_CODE";
export const UPDATE_PROMO_CODE_SUCCESS = "UPDATE_PROMO_CODE_SUCCESS";

//action order print
export const GET_ORDER_PRINT = "GET_ORDER_PRINT";
export const UPDATE_ORDER_PRINT = "UPDATE_ORDER_PRINT";
export const GET_BAKERY_ORDER_PRINT = "GET_BAKERY_ORDER_PRINT";
export const UPDATE_BAKERY_ORDER_PRINT = "UPDATE_BAKERY_ORDER_PRINT";

export const SUCCESS_GET_ORDER_PRINT = "SUCCESS_GET_ORDER_PRINT";
export const FAILURE_GET_ORDER_PRINT = "FAILURE_GET_ORDER_PRINT";
export const SUCCESS_GET_BAKERY_ORDER_PRINT = "SUCCESS_GET_BAKERY_ORDER_PRINT";
export const FAILURE_GET_BAKERY_ORDER_PRINT = "FAILURE_GET_BAKERY_ORDER_PRINT";

//action order
export const GET_ORDER = "GET_ORDER";
export const SUCCESS_GET_ORDER = "SUCCESS_GET_ORDER";
export const FAILURE_GET_ORDER = "FAILURE_GET_ORDER";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const SUCCESS_UPDATE_ORDER = "SUCCESS_UPDATE_ORDER";
export const FAILURE_UPDATE_ORDER = "FAILURE_UPDATE_ORDER";

export const GET_DETAIL_ORDER = "GET_DETAIL_ORDER";
export const SUCCESS_GET_DETAIL_ORDER = "SUCCESS_GET_DETAIL_ORDER";
export const FAILURE_GET_DETAIL_ORDER = "FAILURE_GET_DETAIL_ORDER";

//action notification
export const IS_UPDATE_NOTIFICATION = "IS_UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const FAILURE_UPDATE_NOTIFICATION = "FAILURE_UPDATE_NOTIFICATION";
export const IS_SEND_NOTIFICATION = "IS_SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const SUCCESS_GET_NOTIFICATION = "SUCCESS_GET_NOTIFICATION";
export const FAILURE_GET_NOTIFICATION = "FAILURE_GET_NOTIFICATION";
export const IS_DELETE_NOTIFICATION = "IS_DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const FAILURE_DELETE_NOTIFICATION = "FAILURE_DELETE_NOTIFICATION";

//action drink
export const GET_DRINK = "GET_DRINK";
export const SUCCESS_GET_DRINK = "SUCCESS_GET_DRINK";
export const FAILURE_GET_DRINK = "FAILURE_GET_DRINK";
export const IS_UPDATE_DRINK = "IS_UPDATE_DRINK";
export const UPDATE_DRINK_SUCCESS = "UPDATE_DRINK_SUCCESS";
export const FAILURE_UPDATE_DRINK = "FAILURE_UPDATE_DRINK";
export const IS_ADD_DRINK = "IS_ADD_DRINK";
export const ADD_DRINK_SUCCESS = "ADD_DRINK_SUCCESS";
export const FAILURE_ADD_DRINK = "FAILURE_ADD_DRINK";
export const IS_DELETE_DRINK = "IS_DELETE_DRINK";
export const DELETE_DRINK_SUCCESS = "DELETE_DRINK_SUCCESS";
export const FAILURE_DELETE_DRINK = "FAILURE_DELETE_DRINK";
export const GET_DETAILS_DRINK = "GET_DETAILS_DRINK";
export const SUCCESS_GET_DETAILS_DRINK = "SUCCESS_GET_DETAILS_DRINK";
export const FAILURE_GET_DETAILS_DRINK = "FAILURE_GET_DETAILS_DRINK";

//action dessert
export const GET_DESSERT = "GET_DESSERT";
export const SUCCESS_GET_DESSERT = "SUCCESS_GET_DESSERT";
export const FAILURE_GET_DESSERT = "FAILURE_GET_DESSERT";
export const IS_UPDATE_DESSERT = "IS_UPDATE_DESSERT";
export const UPDATE_DESSERT_SUCCESS = "UPDATE_DESSERT_SUCCESS";
export const FAILURE_UPDATE_DESSERT = "FAILURE_UPDATE_DESSERT";
export const IS_ADD_DESSERT = "IS_ADD_DESSERT";
export const ADD_DESSERT_SUCCESS = "ADD_DESSERT_SUCCESS";
export const FAILURE_ADD_DESSERT = "FAILURE_ADD_DESSERT";
export const IS_DELETE_DESSERT = "IS_DELETE_DESSERT";
export const DELETE_DESSERT_SUCCESS = "DELETE_DESSERT_SUCCESS";
export const FAILURE_DELETE_DESSERT = "FAILURE_DELETE_DESSERT";
export const GET_DETAILS_DESSERT = "GET_DETAILS_DESSERT";
export const SUCCESS_GET_DETAILS_DESSERT = "SUCCESS_GET_DETAILS_DESSERT";
export const FAILURE_GET_DETAILS_DESSERT = "FAILURE_GET_DETAILS_DESSERT";

//action pizza
export const GET_PIZZA = "GET_PIZZA";
export const SUCCESS_GET_PIZZA = "SUCCESS_GET_PIZZA";
export const FAILURE_GET_PIZZA = "SUCCESS_GET_PIZZA";
export const IS_UPDATE_PIZZA = "IS_UPDATE_PIZZA";
export const UPDATE_PIZZA_SUCCESS = "UPDATE_PIZZA_SUCCESS";
export const FAILURE_UPDATE_PIZZA = "FAILURE_UPDATE_PIZZA";
export const IS_ADD_PIZZA = "IS_ADD_PIZZA";
export const ADD_PIZZA_SUCCESS = "ADD_PIZZA_SUCCESS";
export const FAILURE_ADD_PIZZA = "FAILURE_ADD_PIZZA";
export const IS_DELETE_PIZZA = "IS_DELETE_PIZZA";
export const DELETE_PIZZA_SUCCESS = "DELETE_PIZZA_SUCCESS";
export const FAILURE_DELETE_PIZZA = "FAILURE_DELETE_PIZZA";
export const GET_DETAILS_PIZZA = "GET_DETAILS_PIZZA";
export const SUCCESS_GET_DETAILS_PIZZA = "SUCCESS_GET_DETAILS_PIZZA";
export const FAILURE_GET_DETAILS_PIZZA = "FAILURE_GET_DETAILS_PIZZA";

export const GET_TOPPING = "GET_TOPPING";
export const SUCCESS_GET_TOPPING = "SUCCESS_GET_TOPPING";
export const FAILURE_GET_TOPPING = "SUCCESS_GET_TOPPING";
export const IS_UPDATE_TOPPING = "IS_UPDATE_TOPPING";
export const UPDATE_TOPPING_SUCCESS = "UPDATE_TOPPING_SUCCESS";
export const FAILURE_UPDATE_TOPPING = "FAILURE_UPDATE_TOPPING";
export const IS_ADD_TOPPING = "IS_ADD_TOPPING";
export const ADD_TOPPING_SUCCESS = "ADD_TOPPING_SUCCESS";
export const FAILURE_ADD_TOPPING = "FAILURE_ADD_TOPPING";
export const IS_DELETE_TOPPING = "IS_DELETE_TOPPING";
export const DELETE_TOPPING_SUCCESS = "DELETE_TOPPING_SUCCESS";
export const FAILURE_DELETE_TOPPING = "FAILURE_DELETE_TOPPING";
export const TOPPING_UPDATE_PARTIAL_DATA = "TOPPING_UPDATE_PARTIAL_DATA";
export const TOPPING_UPDATE_PARTIAL_DATA_SUCCESS =
  "TOPPING_UPDATE_PARTIAL_DATA_SUCCESS";
export const FAILURE_TOPPING_UPDATE_PARTIAL_DATA =
  "FAILURE_TOPPING_UPDATE_PARTIAL_DATA";
export const GET_DETAILS_TOPPING = "GET_DETAILS_TOPPING";
export const SUCCESS_GET_DETAILS_TOPPING = "SUCCESS_GET_DETAILS_TOPPING";
export const FAILURE_GET_DETAILS_TOPPING = "FAILURE_GET_DETAILS_TOPPING";

//action banner
export const GET_BANNER = "GET_BANNER";
export const SUCCESS_GET_BANNER = "SUCCESS_GET_BANNER";
export const FAILURE_GET_BANNER = "FAILURE_GET_BANNER";

export const ADD_BANNER = "ADD_BANNER";
export const SUCCESS_ADD_BANNER = "SUCCESS_ADD_BANNER";
export const FAILURE_ADD_BANNER = "FAILURE_ADD_BANNER";

export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const FAILURE_UPDATE_BANNER = "FAILURE_UPDATE_BANNER";

export const UPDATE_INDEX_BANNER = "UPDATE_INDEX_BANNER";
export const UPDATE_INDEX_BANNER_SUCCESS = "UPDATE_INDEX_BANNER_SUCCESS";
export const FAILURE_UPDATE_INDEX_BANNER = "FAILURE_UPDATE_INDEX_BANNER";

export const IS_DELETE_BANNER = "IS_DELETE_BANNER";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const FAILURE_DELETE_BANNER = "FAILURE_DELETE_BANNER";

export const GET_POSTCODE = "GET_POSTCODE";
export const SUCCESS_GET_POSTCODE = "SUCCESS_GET_POSTCODE";
export const FAILURE_GET_POSTCODE = "FAILURE_GET_POSTCODE";
export const IS_UPDATE_POSTCODE = "IS_UPDATE_POSTCODE";
export const UPDATE_POSTCODE_SUCCESS = "UPDATE_POSTCODE_SUCCESS";
export const FAILURE_UPDATE_POSTCODE = "FAILURE_UPDATE_POSTCODE";
export const IS_ADD_POSTCODE = "IS_ADD_POSTCODE";
export const ADD_POSTCODE_SUCCESS = "ADD_POSTCODE_SUCCESS";
export const FAILURE_ADD_POSTCODE = "FAILURE_ADD_POSTCODE";
export const IS_DELETE_POSTCODE = "IS_DELETE_POSTCODE";
export const DELETE_POSTCODE_SUCCESS = "DELETE_POSTCODE_SUCCESS";
export const FAILURE_DELETE_POSTCODE = "FAILURE_DELETE_POSTCODE";
export const GET_DETAILS_POSTCODE = "GET_DETAILS_POSTCODE";
export const SUCCESS_GET_DETAILS_POSTCODE = "SUCCESS_GET_DETAILS_POSTCODE";
export const FAILURE_GET_DETAILS_POSTCODE = "FAILURE_GET_DETAILS_POSTCODE";
