/** @flow */
import {
  GET_REPORT,
  SUCCESS_GET_REPORT,
  FAILURE_GET_REPORT
} from "constants/ActionTypes";
import _ from "lodash";
type State = {
  isLoadingReport: boolean,
  report: any,
  foods: array
};

const initState = {
  isLoadingReport: false,
  foods: [],
  totalMoney: 0,
  totalMoneyAfterDiscount: 0,
  promoCodes: [],
  list_items: []
};

const reportReducer = (state: State = initState, action) => {
  switch (action.type) {
    case GET_REPORT:
      return Object.assign({}, state, {
        isLoadingReport: true
      });
    case SUCCESS_GET_REPORT:
      let all_today_items = [];
      let allFoods = [];
      let allCodes = [];
      let allMoney = 0;
      let allMoneyAfterDiscount = 0;
      let data = _.get(action, "payload.data.data");
      if (data) {
        let allItem = data;
        allFoods = allItem.foods;
        allCodes = allItem.discountCodeUse;
        allMoney = allItem.totalMoney;
        allMoneyAfterDiscount = allItem.totalMoneyAfterDiscount;
      }

      return Object.assign({}, state, {
        isLoadingReport: false,
        list_items: all_today_items,
        totalMoney: allMoney,
        totalMoneyAfterDiscount: allMoneyAfterDiscount,
        foods: allFoods,
        promoCodes: allCodes
      });
    case FAILURE_GET_REPORT:
      return Object.assign({}, state, {
        isLoadingReport: false
      });
    default:
      return state;
  }
};

export default reportReducer;
